<template>
    <div class="container">
        <div class="banner">
            <img :src="banner?.src"/>
        </div>
        <div class="introduce">
            <div class="navtitle">
                <div class="title">运东简介</div>
                <div class="horizontal_line"></div>
            </div>
            <div class="values">
                <img :src="image.src">
                <p>{{ intro }}</p>
                <div class="line"></div>
                <div class="slogan">{{ slogan }}</div>
                <div class="footer_list">
                    <div class="footer_list_l">
                        <span class="title">品牌使命</span>
                        <span class="intro">{{ mission }}</span>
                    </div>
                    <div class="footer_list_r">
                        <span class="title">价值观</span>
                        <span class="intro">{{ values }}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="barnd">
            <div class="navtitle">
                <div class="title" style="color: #fff">品牌价值</div>
                <div class="horizontal_line"></div>
            </div>
            <div class="brand_list">
                <div class="brand_list_item" v-for="(item, index) in adv" :key="index">
                    <div class="item_top">
                        <img :src="item.icon" />
                        <span class="title">{{ item.title }}</span>
                    </div>
                    <div class="item_f">
                        {{ item.intro }}
                    </div>
                </div>
            </div>
        </div>
        <div class="history">
            <div class="navtitle">
                <div class="title">发展历程</div>
                <div class="horizontal_line"></div>
            </div>
            <div class="slide">
                <div class="slide_top">
                    <img src="../../../assets/image/m_sh.png">
                </div>
                <div class="slide_center" :style="{ height: `${historyHeight}px` }">
                    <div class="history_list_item" ref="historyItem" :class="index%2 == 0?'left':'right'" v-for="(item, index) in histories" :key="index">
                        <div class="line"></div>
                        <div class="info">
                            <span class="title">{{ item.start_year }} ~ {{ item.end_year }}</span>
                            <div class="intro">{{ item.intro }}</div>
                        </div>
                    </div>
                </div>
                <div class="slide_btm">
                    <img src="../../../assets/image/m_xh.png">
                </div>
            </div>
        </div>
        <div class="honor">
            <div class="navtitle">
                <div class="title">企业荣誉</div>
                <div class="horizontal_line"></div>
            </div>
            <div class="honor_list">
                <div class="honor_list_item" v-for="(item, index) in honors" :key="index">
                    <div class="year">{{ item.got_year }}</div>
                    <div class="line"></div>
                    <div class="content">
                        {{ item.title }}
                    </div>
                </div>
            </div>
        </div>
        <div class="footer">
            <div class="main">
                <div class="title">寻找发光的你，挑战高薪，“职”等你来</div>
                <div class="sub_title">Join us</div>
                <span>
                    长期招聘：
                    <span v-for="(item, index) in jobs" :key="index">
                        {{ item }}<span v-if="index != jobs.length - 1">、</span>
                    </span>
                </span>
                <span class="email">简历投递邮箱：{{ resume_email }}</span>
            </div>
        </div>
        <mobile-Loading v-if="isLoading"/>
    </div>
</template>
<script>
import { getData } from "@/api/aboutUs";
export default {
    data() {
        return {
            historyHeight: 0,
            banner: {},
            histories: [],
            intro: "",
            mission: "",
            slogan: "",
            honors: [],
            adv: [],
            image: {},
            resume_email: "",
            values: "",
            jobs: [],
            isLoading: true
        }
    },
    mounted() {
        this.initData();
        this.$nextTick(() => {
            let newArr = this.$refs.historyItem?.slice(0,4)
            let totalHeight = 0
            newArr?.forEach(item => {
                totalHeight += item.offsetHeight
            })
            this.historyHeight = totalHeight || 490
        })
    },
    methods: {
        async initData() {
            try {
                const { data: res } = await getData();
                this.banner = res.banner;
                res.histories.sort((a, b) => {
                return Number(a.start_year) - Number(b.start_year);
                });
                this.histories = res.histories;
                this.intro = res.intro;
                this.mission = res.mission;
                this.slogan = res.slogan;
                res.honors.sort((a, b) => {
                return Number(a.got_year) - Number(b.got_year);
                });
                this.honors = res.honors;
                this.adv = res.adv;
                this.image = res.image;
                this.resume_email = res.resume_email;
                this.values = res.values;
                this.jobs = res.jobs;
            }finally {
                this.isLoading = false
            }
        }
    }
}
</script>
<style scoped lang="scss">
.navtitle {
    .title {
        color: #FFA71D;
        font-size: 36px;
        font-weight: bold;
        width: 100%;
        text-align: center;
    }
    .horizontal_line {
        width: 150px;
        height: 2px;
        background-color: #EEEEEE;
        position: relative;
        margin: 24px auto;
        margin-bottom: 50px;
        &::after {
            position: absolute;
            content: '';
            width: 40px;
            height: 4px;
            background-color: #FFA71D;
            left: 50%;
            top: -1px;
            transform: translateX(-50%);
        }
    }
}
.container {
    .banner {
        img {
            width: 100%;
            height: 400px;
            object-fit: cover;
        }
    }
    .introduce {
        padding: 50px 30px;
        box-sizing: border-box;
        .values {
            img {
                width: 100%;
                height: 400px;
                object-fit: cover;
            }
            p {
                font-size: 28px;
                color: #666666;
                text-indent: 2em;
                margin: 40px 0;
            }
            .line {
                width: 100%;
                height: 1px;
                background: #eee;
            }
            .slogan{
                color: #333333;
                font-size: 28px;
                font-weight: bold;
                margin: 38px 0;
            }
            .footer_list {
                display: flex;
                column-gap: 18px;
                width: 100%;
                height: 250px;
                color: #fff;
                font-size: 24px;
                &_l {
                    flex: 1;
                    background: url('../../../assets/image/m_ppsm.png') 100% 100% no-repeat;
                    background-size: cover;
                    background-position: center;
                    padding: 30px 20px;
                    box-sizing: border-box;
                    display: flex;
                    flex-direction: column;
                    row-gap: 18px;
                    .title {
                        font-size: 28px;
                        font-weight: bold;
                    }
                }
                &_r {
                    flex: 1;
                    background: url('../../../assets/image/m_jzg.png') 100% 100% no-repeat;
                    background-size: cover;
                    background-position: center;
                    padding: 30px 20px;
                    box-sizing: border-box;
                    display: flex;
                    flex-direction: column;
                    row-gap: 18px;
                    .title {
                        font-size: 28px;
                        font-weight: bold;
                    }
                }
            }
        }
    }
    .barnd {
        padding: 50px 30px;
        box-sizing: border-box;
        width: 100%;
        height: 890px;
        background: url('../../../assets/image/m_ppjz .png') 100% 100% no-repeat;
        background-size: cover;
        background-position: center;
        .horizontal_line {
            background: rgba($color: #eee, $alpha: 0.3);
            &::after {
                background: #fff;
            }
        }
        .brand_list {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 18px;
            &_item {
                background: #fff;
                border-radius: 6px;
                padding: 31px 21px;
                box-sizing: border-box;
                .item_top {
                    display: flex;
                    column-gap: 15px;
                    align-content: center;
                    img {
                        width: 56px;
                        height: 56px;
                    }
                    .title {
                        font-size: 28px;
                        color: #333333;
                        line-height: 56px;
                        font-weight: bold;
                    }
                }
                .item_f {
                    font-size: 24px;
                    color: #666666;
                    margin-top: 24px;
                }
                
            }
        }
    }
    .history {
        padding: 50px 30px;
        box-sizing: border-box;
        background: #FFF9EF;
        .slide {
            display: flex;
            flex-direction: column;
            row-gap: 24px;
            align-items: center;
            &_top {
                img{ 
                    width: 44px;
                    height: 44px;
                }
            }
            &_center {
                width: 100%;
                background: url('../../../assets/image/m_dt.png') no-repeat;
                background-position: center;
                background-size: 100% 58%;
                overflow-y: scroll;
                &::-webkit-scrollbar {
                    width: 0;
                    background: transparent;
                }
                .history_list_item {
                    display: flex;
                    column-gap: 24px;
                    .line {
                        width: 2px;
                        height: auto;
                        background: #FFA71D;
                        flex-shrink: 0;
                        position: relative;
                        &::after {
                            content: '';
                            width: 18px;
                            height: 18px;
                            border: 2px solid #FFA71D;
                            border-radius: 50%;
                            background: #fff;
                            position: absolute;
                            left: 50%;
                            top: 20px;
                            transform: translate(-50%,50%);
                        }
                    }
                    .info {
                        padding: 20px 0;
                        box-sizing: border-box;
                        .title {
                            color: #FFA71D;
                            font-size: 28px;
                            font-weight: bold;
                            width: 100%;
                            display: inline-block;
                        }
                        .intro {
                            color: #666666;
                            font-size: 24px;
                            border: 1px solid #EEEEEE;
                            background: #FFFFFF;
                            width: 320px;
                            padding: 26px 20px;
                            box-sizing: border-box;
                            margin-top: 20px;
                            position: relative;
                        }
                    }
                }
                .left {
                    flex-direction: row-reverse;
                    .line {
                        margin-right: 50%;
                        transform: translateX(50%);
                    }
                    .title {
                        text-align: right;
                    }
                }
                .right { 
                    .line {
                        margin-left: 50%;
                        transform: translateX(-50%);
                    }
                }
            }
            &_btm {
                img{ 
                    width: 44px;
                    height: 44px;
                }
            }
        }
    }
    .honor {
        padding: 50px 30px;
        box-sizing: border-box;
        .honor_list {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 50px;
            &_item {
                height: 200px;
                width: 100%;
                background: url('../../../assets/image/m_qyry.png') 100% 100% no-repeat;
                background-position: center;
                background-size: cover;
                display: flex;
                flex-direction: column;
                align-items: center;
                display: flex;
                flex-direction: column;
                row-gap: 16px;
                padding-top: 20px;
                box-sizing: border-box;
                .year {
                    color: #ffa71d;
                    font-size: 28px;
                    font-weight: bold;
                }
                .line {
                    width: 140px;
                    height: 1px;
                    background: #eeeeee;
                }
                .content {
                    color: #666;
                    font-size: 16px;
                    text-align: center;
                    width: 143px;
                }
            }
        }
    }
    .footer {
        width: 100%;
        height: 500px;
        background: url("../../../assets/image/m_jrwm.png") 100% 100% no-repeat;
        background-position: center;
        background-size: cover;
        .main {
            width: 100%;
            height: 100%;
            padding: 80px 29px;
            box-sizing: border-box;
            margin: 0 auto;
            color: #fff;
            font-size: 28px;
        .title {
            font-size: 36px;
            margin-bottom: 10px;
        }
        .sub_title {
            font-size: 80px;
            font-weight: 900;
            opacity: 0.3;
            margin-bottom: 50px;
        }
        span {
            margin-bottom: 20px;
        }
        .email {
            display: block;
            margin-top: 20px;
        }
        }
    }
}
</style>